<template>
  <ViewTemplate title="Patient">
    <LineMenu>
      <Button
        label="Add Patient"
        class="p-mr-2 p-button-raised p-button-rounded"
        @click="addPatient"
      />
      <Button
        label="Filter"
        icon="pi pi-filter"
        class="p-mr-2 p-button-rounded p-button-text p-button-plain"
        @click="searchOverlay"
      />
      <OverlayPanel ref="op">
        <div
          v-for="(gender, i) of Gender.values"
          :key="i"
          class="p-field-checkbox"
        >
          <Checkbox
            :id="gender.code"
            name="gender"
            :value="gender"
            v-model="searchGender"
          />
          <label :for="gender.code">{{ gender.name }}</label>
        </div>
      </OverlayPanel>
      <Dropdown
        v-model="searchDropdownValue"
        optionLabel="name"
        optionValue="code"
        :options="searchDropdown"
      />
      <InputText v-model="searchText" type="text" />
      <Button
        label="Search"
        icon="pi pi-search"
        class="p-button-success"
        @click="search"
      />
    </LineMenu>
    <Message v-if="errorWhilePosting" severity="error">
      Unknown error while saving the patient. Please contact system
      administration
    </Message>
    <TabView class="p-mb-3">
      <TabPanel header="Patient">
        <DataTable
          class="p-datatable-striped"
          :value="patients"
          :lazy="true"
          :totalRecords="patientsCount"
          :paginator="true"
          :rows="ROWS"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          sortMode="multiple"
          removableSort
          dataKey="id"
          @page="pageEvent"
          @sort="sortEvent"
          :rowClass="getNewsRowsStyle"
        >
          <Column
            field="patientid"
            header="Patient Id"
            :sortable="true"
          ></Column>
          <Column
            field="patientid1"
            header="T-number"
            :sortable="true"
          ></Column>
          <Column field="patcenter" header="TC" :sortable="true"></Column>
          <Column field="lastname" header="Last Name" :sortable="true"></Column>
          <Column
            field="firstname"
            header="First Name"
            :sortable="true"
          ></Column>
          <Column field="birthdate" header="Date of Birth" :sortable="true">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.birthdate) }}
            </template>
          </Column>
          <Column field="gender" header="Gender" :sortable="true">
            <template #body="slotProps">
              {{ Gender.byCode(slotProps.data.gender) }}
            </template>
          </Column>
          <Column field="status" header="State" :sortable="true">
            <template #body="slotProps">
              {{ Status.byCode(slotProps.data.status) }}
            </template>
          </Column>
          <Column header="Edit">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text"
                @click="selectPat(slotProps.data)"
              />
            </template>
          </Column>
          <Column header="Recommended Donors">
            <template #body="slotProps">
              <Button
                icon="pi pi-list"
                class="p-button-rounded p-button-text"
                @click="showRecommendedDonors(slotProps.data)"
              />
            </template>
          </Column>
          <Column header="Record update">
            <template #body="slotProps">
              <InlineMessage
                v-if="slotProps.data.news"
                v-tooltip="slotProps.data.news"
                severity="info"
              >
                News
              </InlineMessage>
            </template>
          </Column>
        </DataTable>
      </TabPanel>
      <TabPanel header="Patient In Process">
        <DataTable
          class="p-datatable-striped"
          :value="ataPatients"
          :lazy="true"
          :totalRecords="ataPatientsCount"
          :paginator="true"
          :rows="ROWS"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          sortMode="multiple"
          removableSort
          dataKey="id"
          @page="ataPageEvent"
          @sort="ataSortEvent"
        >
          <Column
            field="patientid"
            header="Patient Id"
            :sortable="true"
          ></Column>
          <Column
            field="patientid1"
            header="T-number"
            :sortable="true"
          ></Column>
          <Column field="patcenter" header="TC" :sortable="true"></Column>
          <Column field="lastname" header="Last Name" :sortable="true"></Column>
          <Column
            field="firstname"
            header="First Name"
            :sortable="true"
          ></Column>
          <Column field="birthdate" header="Date of Birth" :sortable="true">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.birthdate) }}
            </template>
          </Column>
          <Column field="gender" header="Gender" :sortable="true">
            <template #body="slotProps">
              {{ Gender.byCode(slotProps.data.gender) }}
            </template>
          </Column>
          <Column field="status" header="State" :sortable="true">
            <template #body="slotProps">
              {{ Status.byCode(slotProps.data.status) }}
            </template>
          </Column>
          <Column header="Record update">
            <template #body="slotProps">
              <InlineMessage
                :severity="slotProps.data.patientid ? 'warn' : 'info'"
              >
                <span v-if="slotProps.data.patientid">Edit</span>
                <span v-else>New</span>
              </InlineMessage>
            </template>
          </Column>
        </DataTable>
      </TabPanel>
    </TabView>
  </ViewTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AtaTmpPat from "@/model/ataTmpPat";
import Gender from "@/model/enums/gender";
import Status from "@/model/enums/status";
import LineMenu from "@/components/LineMenu.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InlineMessage from "primevue/inlinemessage";
import Message from "primevue/message";
import OverlayPanel from "primevue/overlaypanel";
import Checkbox from "primevue/checkbox";
import { namespace } from "vuex-class";
import EnumValue from "@/model/enums";
const ataTmpPat = namespace("ataTmpPat");
const recommendedDonors = namespace("recommendedDonors");

@Options({
  components: {
    LineMenu,
    DataTable,
    Column,
    Dropdown,
    InputText,
    TabView,
    TabPanel,
    InlineMessage,
    Message,
    OverlayPanel,
    Checkbox
  }
})
export default class PatientList extends Vue {
  declare $refs: {
    op: HTMLFormElement;
  };
  ROWS = 5;
  filter = "";
  sort = "";
  @ataTmpPat.State
  patients!: Array<AtaTmpPat>;
  @ataTmpPat.State
  ataPatients!: Array<AtaTmpPat>;
  @ataTmpPat.State
  patientsCount!: number;
  @ataTmpPat.State
  ataPatientsCount!: number;
  @ataTmpPat.State
  errorWhilePosting!: boolean;
  searchDropdown: EnumValue[] = [
    {
      name: "All",
      code: "all"
    },
    {
      name: "Patient Id",
      code: "patientid"
    },
    {
      name: "T-number",
      code: "patientid1"
    },
    {
      name: "Last Name",
      code: "lastname"
    },
    {
      name: "First Name",
      code: "firstname"
    }
  ];
  searchDropdownValue = this.searchDropdown[0].code;
  searchText = "";
  searchGender = [];

  Gender = Gender;
  Status = Status;

  async mounted() {
    await this.pageEvent({ page: 0 });
    await this.ataPageEvent({ page: 0 });
  }

  async pageEvent(event: { page: number }) {
    await this.fetchPatients({
      size: this.ROWS,
      page: event.page,
      filter: this.filter.replace(
        "lastname~caseinsensitivecontains~",
        "name~caseinsensitivecontains~"
      ),
      sort: this.sort
    });
  }

  async ataPageEvent(event: { page: number }) {
    await this.fetchAtaPatients({
      size: this.ROWS,
      page: event.page,
      filter: this.filter.replace("sex~eq~", "gender~eq~"),
      sort: this.sort
    });
  }

  async sortEvent(event: {
    multiSortMeta: { field: string; order: number }[];
  }) {
    const map = new Map();
    map.set("patientid", "patientid");
    map.set("patientid1", "patientid1");
    map.set("patcenter", "bmdpat2.patcenter");
    map.set("lastname", "name");
    map.set("firstname", "firstname");
    map.set("birthdate", "birthdate");
    map.set("gender", "sex");
    map.set("status", "patstate");
    //patientnum&patientnum.dir=asc
    this.sort = "";
    event.multiSortMeta.forEach(e => {
      const f = map.get(e.field);
      this.sort += `${f}:${e.order === 1 ? "ASC" : "DESC"}`;
    });
    await this.pageEvent({ page: 0 });
  }

  async ataSortEvent(event: {
    multiSortMeta: { field: string; order: number }[];
  }) {
    //patientnum&patientnum.dir=asc
    this.sort = "";
    event.multiSortMeta.forEach(e => {
      this.sort += `${e.field}:${e.order === 1 ? "ASC" : "DESC"}`;
    });
    await this.ataPageEvent({ page: 0 });
  }

  async search() {
    if (this.searchText == "") this.filter = "";
    else if (this.searchDropdownValue == "all") {
      this.filter = Object.values(this.searchDropdown)
        .slice(1)
        .map(el => `${el.code}~caseinsensitivecontains~${this.searchText}`)
        .join("~or~");
    } else {
      this.filter = `${this.searchDropdownValue}~caseinsensitivecontains~${this.searchText}`;
    }
    // if (this.searchDropdownGender != Gender.UNKNOWN.code) {
    //   this.filter = this.filter ? `(${this.filter})~and~` : "";
    //   this.filter += `sex~eq~${this.searchDropdownGender}`;
    // }
    console.log(this.searchGender);
    // console.log(this.filter);
    await this.pageEvent({ page: 0 });
    await this.ataPageEvent({ page: 0 });
  }

  searchOverlay(event: object) {
    this.$refs.op.toggle(event);
  }

  getNewsRowsStyle(pat: AtaTmpPat) {
    return pat.news ? "news" : "";
  }

  formatDate(date: string) {
    const d = new Date(date);
    return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`;
  }

  @ataTmpPat.Action
  fetchPatients!: ({
    page,
    size,
    filter,
    sort
  }: {
    size: number;
    page: number;
    filter?: string;
    sort?: string;
  }) => void;

  @ataTmpPat.Action
  fetchAtaPatients!: ({
    page,
    size,
    filter,
    sort
  }: {
    size: number;
    page: number;
    filter?: string;
    sort?: string;
  }) => void;

  @ataTmpPat.Action
  selectPat!: (patient: AtaTmpPat) => void;

  @ataTmpPat.Action
  addPatient!: () => void;

  @recommendedDonors.Action
  showRecommendedDonors!: (patient: AtaTmpPat) => void;
}
</script>

<style scoped>
:deep(.news) {
  background: #ffff74 !important;
}

.v-title-view {
  /*font-family: $title;*/
  color: #1c233f;
  font-weight: bold;
  font-size: 25px;
}
.v-menu-view {
  background: #1c233f;
}
.v-menu-button {
  margin-left: 20px;
  color: #2196f3;
  border-radius: 25px;
  background: #ffffff;
}
.v-menu-button:hover {
  color: #ffffff;
  background: #2196f3;
}

.v-menu-save {
  margin-left: 20px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 1px;
  background: #4caf50;
}

.v-menu-clear {
  margin-left: 20px;
  color: #ffffff;
  border-radius: 25px;
  background: #4caf50;
}

.v-menu-label {
  margin-left: 20px;
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
}

.v-menu-view {
  background: #1c233f;
}
</style>
