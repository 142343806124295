
import { Options, Vue } from "vue-class-component";
import AtaTmpPat from "@/model/ataTmpPat";
import Gender from "@/model/enums/gender";
import Status from "@/model/enums/status";
import LineMenu from "@/components/LineMenu.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InlineMessage from "primevue/inlinemessage";
import Message from "primevue/message";
import OverlayPanel from "primevue/overlaypanel";
import Checkbox from "primevue/checkbox";
import { namespace } from "vuex-class";
import EnumValue from "@/model/enums";
const ataTmpPat = namespace("ataTmpPat");
const recommendedDonors = namespace("recommendedDonors");

@Options({
  components: {
    LineMenu,
    DataTable,
    Column,
    Dropdown,
    InputText,
    TabView,
    TabPanel,
    InlineMessage,
    Message,
    OverlayPanel,
    Checkbox
  }
})
export default class PatientList extends Vue {
  declare $refs: {
    op: HTMLFormElement;
  };
  ROWS = 5;
  filter = "";
  sort = "";
  @ataTmpPat.State
  patients!: Array<AtaTmpPat>;
  @ataTmpPat.State
  ataPatients!: Array<AtaTmpPat>;
  @ataTmpPat.State
  patientsCount!: number;
  @ataTmpPat.State
  ataPatientsCount!: number;
  @ataTmpPat.State
  errorWhilePosting!: boolean;
  searchDropdown: EnumValue[] = [
    {
      name: "All",
      code: "all"
    },
    {
      name: "Patient Id",
      code: "patientid"
    },
    {
      name: "T-number",
      code: "patientid1"
    },
    {
      name: "Last Name",
      code: "lastname"
    },
    {
      name: "First Name",
      code: "firstname"
    }
  ];
  searchDropdownValue = this.searchDropdown[0].code;
  searchText = "";
  searchGender = [];

  Gender = Gender;
  Status = Status;

  async mounted() {
    await this.pageEvent({ page: 0 });
    await this.ataPageEvent({ page: 0 });
  }

  async pageEvent(event: { page: number }) {
    await this.fetchPatients({
      size: this.ROWS,
      page: event.page,
      filter: this.filter.replace(
        "lastname~caseinsensitivecontains~",
        "name~caseinsensitivecontains~"
      ),
      sort: this.sort
    });
  }

  async ataPageEvent(event: { page: number }) {
    await this.fetchAtaPatients({
      size: this.ROWS,
      page: event.page,
      filter: this.filter.replace("sex~eq~", "gender~eq~"),
      sort: this.sort
    });
  }

  async sortEvent(event: {
    multiSortMeta: { field: string; order: number }[];
  }) {
    const map = new Map();
    map.set("patientid", "patientid");
    map.set("patientid1", "patientid1");
    map.set("patcenter", "bmdpat2.patcenter");
    map.set("lastname", "name");
    map.set("firstname", "firstname");
    map.set("birthdate", "birthdate");
    map.set("gender", "sex");
    map.set("status", "patstate");
    //patientnum&patientnum.dir=asc
    this.sort = "";
    event.multiSortMeta.forEach(e => {
      const f = map.get(e.field);
      this.sort += `${f}:${e.order === 1 ? "ASC" : "DESC"}`;
    });
    await this.pageEvent({ page: 0 });
  }

  async ataSortEvent(event: {
    multiSortMeta: { field: string; order: number }[];
  }) {
    //patientnum&patientnum.dir=asc
    this.sort = "";
    event.multiSortMeta.forEach(e => {
      this.sort += `${e.field}:${e.order === 1 ? "ASC" : "DESC"}`;
    });
    await this.ataPageEvent({ page: 0 });
  }

  async search() {
    if (this.searchText == "") this.filter = "";
    else if (this.searchDropdownValue == "all") {
      this.filter = Object.values(this.searchDropdown)
        .slice(1)
        .map(el => `${el.code}~caseinsensitivecontains~${this.searchText}`)
        .join("~or~");
    } else {
      this.filter = `${this.searchDropdownValue}~caseinsensitivecontains~${this.searchText}`;
    }
    // if (this.searchDropdownGender != Gender.UNKNOWN.code) {
    //   this.filter = this.filter ? `(${this.filter})~and~` : "";
    //   this.filter += `sex~eq~${this.searchDropdownGender}`;
    // }
    console.log(this.searchGender);
    // console.log(this.filter);
    await this.pageEvent({ page: 0 });
    await this.ataPageEvent({ page: 0 });
  }

  searchOverlay(event: object) {
    this.$refs.op.toggle(event);
  }

  getNewsRowsStyle(pat: AtaTmpPat) {
    return pat.news ? "news" : "";
  }

  formatDate(date: string) {
    const d = new Date(date);
    return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`;
  }

  @ataTmpPat.Action
  fetchPatients!: ({
    page,
    size,
    filter,
    sort
  }: {
    size: number;
    page: number;
    filter?: string;
    sort?: string;
  }) => void;

  @ataTmpPat.Action
  fetchAtaPatients!: ({
    page,
    size,
    filter,
    sort
  }: {
    size: number;
    page: number;
    filter?: string;
    sort?: string;
  }) => void;

  @ataTmpPat.Action
  selectPat!: (patient: AtaTmpPat) => void;

  @ataTmpPat.Action
  addPatient!: () => void;

  @recommendedDonors.Action
  showRecommendedDonors!: (patient: AtaTmpPat) => void;
}
